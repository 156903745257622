<template>
<v-container>
  <div v-if="failed">
    <v-alert
      outlined
      type="error"
    >
      Failed to sign in with Google. Please close the window and try again.
      <div v-if="message"><strong>Error Message:</strong> {{ message }}</div>
    </v-alert>
  </div>
  <div v-else>
    <v-alert
      outlined
      prominent
      text
      type="success"
    >
      You have successfully signed in with your Google account. <strong>Please close the window to continue.</strong>
    </v-alert>
  </div>
</v-container>
</template>

<script>
/**
 * NOTE: this component is for sign in with Google fallback - when user is using firefox (or android native browser - which i believe is using firefox)
 * This script will register the signed in User and show relevant message to ask user to close the window (as firefox will not able to close window by script)
 */
export default {
  data() {
    return {
      failed: false,
      message: ''
    }
  },
  async mounted() {
    var url_string = window.location.href;
    var url = new URL(url_string);
    var c = url.searchParams.get("code");
    if(c){
      let res = await this.$store.dispatch({
        type: "login",
        provider: "google",
        code: c,
      });
      if (res.error == false) {
        // document.write('SUCCESS')
        // setTimeout(() => window.close(), 500);
        // window.open('', '_self', '')
        // window.close()
      } else {
        // document.write(res.message);
        this.failed = true
        this.message = res.message
      }
    } else {
      // document.write('failed');
      this.failed = true
    }
  },
  created() {
    let loader = document.getElementById("app-loading");
    if(loader) loader.parentNode.removeChild(loader);
    window.close()
  }
};
</script>